import React, { useState, useEffect } from 'react';
import { getFetch, postFetch } from '../../../../../fetch/fetch';
import { DataGridPremium, GridRowModel } from '@mui/x-data-grid-premium';
import { Button, Radio, RadioGroup } from '@mui/joy';
import { Backdrop, Box, CircularProgress, FormControl, Modal, OutlinedInput } from '@mui/material';
import ModalHeaderForMoney from '../etc/modalheader';
import styles from "../money.module.css";
import ModalBottomForMoney from '../etc/modalbottom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
};

const Payment: React.FC<any> = (props) => {

    const [users, setUsers] = useState([]);

    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);

    const [update, setUpdate] = useState(0);
    const [update2, setUpdate2] = useState(0);

    const [rows1, setRows1] = useState<any>([]);
    const [columns1, setColumns1] = useState([
        { field: "date", headerName: "결제일", width: 80, editable : true },
        { field: "name", headerName: "이름", width: 100 },
        { field: "seat", headerName: "자리", width: 80 },
        { field: "parentPhoneNumber", headerName: "학부모번호", width: 150 }
    ]);
    const [selectedId, setSelectedId] = useState();
    const [selectedName, setSelectedName] = useState("");
    const [selectedSeat, setSelectedSeat] = useState("");

    const [rows2, setRows2] = useState<any>([]);
    const [columns2, setColumns] = useState([
        { field: "kind", headerName: "종류", width: 150 },
        { field : "product_nm", headerName : "청구사유", width : 150},
        { field: "price", headerName: "가격", width: 100 },
        { field: "status", headerName: "상태", width: 100 },
        { field : "teacherName", headerName : "책임자", width : 150 },
        { field : "createdAt", headerName : "생성일", width : 150},
        { field: "etc", headerName: "자유메모", width: 150, editable : true },
    ]);

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [disabled2, setDisabled2] = useState(true);

    const [kind, setKind] = useState("link");
    const [price, setPrice] = useState<any>("");
    const [product_nm, setProduct_nm] = useState(""); 

    const notify1 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    useEffect(() => {

        start();

    }, []);

    useEffect(() => {

        if (!selectedId) {
            setDisabled(true);
            return;
        }

        setDisabled(false);

        getUserInfo();

    }, [selectedId, update]);

    useEffect(() => {

        if(!update2){
            return;
        }

        getUserInfo();

    }, [update2]);

    useEffect(() => {

        if(!price || !product_nm){
            setDisabled2(true);
            return;
        }

        setDisabled2(false);

    }, [price, product_nm]);

    const start = async () => {

        setLoading1(true);

        const response: any = await getFetch("/dashboard/payments/paystatus/userlist");

        console.log(response);

        const users = response.data;

        const newRows: any = [];

        users.forEach((each: any) => {

            const oneRow: any = {};

            oneRow.id = each.id;
            oneRow.name = each.name;
            oneRow.seat = each.seat;
            oneRow.parentPhoneNumber = each.parentPhoneNumber;

            const date = Math.floor(Math.random() * (31) + 1);

            oneRow.date = date;

            newRows.push(oneRow);

        });

        newRows.sort((a: any, b: any) => {

            if (!a.seat) {
                a.seat = 0;
            }

            if (!b.seat) {
                b.seat = 0;
            }

            const afterSeat = +a.seat;
            const beforeSeat = +b.seat;

            return afterSeat - beforeSeat;
        })

        //결제 안된 학생 맨 위로 올리고
        //결제일 가까운 학생들 위로 올린다

        //즉 순서는 결제 안된 학생 맨 위, 그다음 결제일 가깝게 남은 애들 순

        setRows1([...newRows]);

        setLoading1(false);

    }

    const getUserInfo = async () => {

        try {

            setLoading2(true);

            if (!selectedId) {
                return;
            }

            const result : any = await getFetch("/dashboard/payments/paystatus/userinfo?userId=" + selectedId);

            const data = result.data;

            const newRows : any = [];

            data.forEach((each : any) => {

                const oneRow : any = {};
                oneRow.id = each.id;
                oneRow.product_nm = each.product_nm;
                
                var value = each.price.toString();
                value = value.replace(/[^0-9]/g, '');
                //value를 3자리마다 콤마를 찍어줌
                value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원";

                oneRow.price = value;
                
                if(each.kind === "link"){
                    oneRow.kind = "결제선생";
                }

                if(each.kind === "other"){
                    oneRow.kind = "기타수단";
                }

                switch (each.status) {
                    case "waiting" :
                        oneRow.status = "결제 대기중";
                        break;
                    case "paid" :
                        oneRow.status = "결제 완료";
                        break;
                    case "canceled" :
                        oneRow.status = "결제 취소 됨";
                        break;
                }

                oneRow.teacherName = each.teacherName;
                oneRow.memo = each.memo;

                const date = new Date(each.createdAt);
                const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}시`;

                oneRow.createdAt = dateString;
                oneRow.etc = each.etc;

                newRows.push(oneRow);

            });

            setRows2([...newRows]);

            setLoading2(false);

        } catch (e) {
            console.log(e);
        }

    }

    const handleClose = () => {
        setPrice("");
        setKind("link");
        setProduct_nm("");
        setOpen(false);
    }

    const submit = async () => {

        try{

        
            if(!selectedId){
                return;
            }

            if(!price){
                return;
            }

            setLoading3(true);
    
            const data = {
                selectedId,
                kind,
                price : parseInt(price.replace(/,/g, ''), 10),
                product_nm
            }
    
            const result : any = await postFetch("/dashboard/payments/paystatus/submit", data);

            if(result.message === "success"){

                notify1();
                handleClose();

                setUpdate(Math.random());

                setLoading3(false);

            }


        }catch(e){
            console.log(e);
        }

    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {

            var field = "";

            console.log("new");

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const id = newRow.id;
            const userId = newRow.userId;

            console.log(newRow);
            console.log(field);
            console.log(value);
            console.log(id);
            console.log(userId);

            if (!field) {
                console.log("noChanged");
                return newRow;
            }

            if(field !== "etc"){
                alert("메모만 수정 가능합니다.");
                return newRow;
            }

            if (!id) {
                console.log("noId");
                return newRow;
            }

            const data = {
                value,
                id,
                field
            }

            console.log(data);

            fetch("https://peetsunbae.com/dashboard/payments/paystatus/memo", {
                method: "PATCH",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            }).then((res: any) => {
                res.json().then((result: any) => {
                    if (result.message === "success") {
                        //setUpdate2(Math.random());
                    }
                })
            }).catch((e: any) => {
                console.log(e);
            });

            return newRow;

        }, []
    );


    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log("error");
        alert(error.message);
    }, []);

    return (
        <div>
            <div style={{
                marginTop: "48px",
                display: "flex",
                minHeight: "24px"
            }}>
                <div style={{
                    width: "450px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    marginRight: "32px"
                }}>
                    <span>
                        {selectedName}
                    </span>
                </div>
                <div style={{
                    width: "1000px",
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <Button
                        onClick={() => {
                            setOpen(true);
                        }}
                        disabled={disabled}
                    >
                        추가하기
                    </Button>
                </div>
            </div>
            <div style={{
                marginTop: "16px",
                display: "flex",
            }}>
                <div style={{
                    width: "450px",
                    height: "900px",
                    marginRight: "32px"
                }}>
                    <DataGridPremium
                        rows={rows1}
                        columns={columns1}
                        density='compact'
                        onRowClick={(params: any) => {
                            console.log(params);
                            setSelectedId(params.id);
                            setSelectedName(params.row.name);
                            setSelectedSeat(params.row.seat);
                        }}
                        loading={loading1}
                    />
                </div>
                <div style={{
                    width: "1000px",
                    height: "900px"
                }}>
                    <DataGridPremium
                        rows={rows2}
                        columns={columns2}
                        density="compact"
                        loading={loading2}
                        processRowUpdate={handleEditCommit}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                    />
                </div>
            </div>

            <Modal
                open={open}
                onClose={() => {
                    handleClose();
                }}
            >
                <Box
                    sx={style}>
                    <ModalHeaderForMoney title="결제 내역 추가" />
                    <div className={styles.modalBody}>
                        <div style={{ height: "1rem" }}></div>
                        <div>
                            <div className={styles.title}>
                                학생 정보
                            </div>
                            <div>
                                <FormControl variant='outlined' fullWidth>
                                    <OutlinedInput
                                        value={`${selectedName} (${selectedSeat}번)`}
                                        size='small'
                                        placeholder='2024'
                                        fullWidth
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div>
                            <div className={styles.title}>
                                청구 사유
                            </div>
                            <div>
                                <FormControl variant='outlined' fullWidth>
                                    <OutlinedInput
                                        value={product_nm}
                                        size='small'
                                        placeholder='청구사유를 적어주세요'
                                        fullWidth
                                        onChange={(e) => {
                                            setProduct_nm(e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div>
                            <div className={styles.title}>
                                결제 수단
                            </div>
                            <div>
                                <RadioGroup value={kind} onChange={(e) => { setKind(e.target.value) }} orientation='horizontal'>
                                    <Radio value="link" label={<span className={styles.radioText}>결제선생</span>} />
                                    <Radio value="other" label={<span className={styles.radioText}>기타</span>} />
                                </RadioGroup>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className={styles.title}>
                                    가격
                                </div>
                                <div>
                                    <FormControl variant='outlined'
                                        fullWidth
                                    >
                                        <OutlinedInput
                                            value={price} onChange={(e) => {
                                                console.log("gogo");
                                                var value = e.target.value;
                                                value = value.replace(/[^0-9]/g, '');
                                                //value를 3자리마다 콤마를 찍어줌
                                                value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                                setPrice(value);
                                            }}
                                            size='small'
                                            endAdornment={<div>원</div>}
                                            fullWidth
                                            placeholder="결제 금액을 입력하세요"
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className={styles.justHeight2}></div>
                    </div>
                    <ModalBottomForMoney disabled={disabled2} buttonName={kind === "link" ? "결제선생 링크 전송" : "결제 완료 처리하기"} handleClick={submit} />
                </Box>
            </Modal>

            <ToastContainer />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999999 }}
                open={loading3}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Payment;