import React, { useEffect, useState, useRef } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GangnamSeatSvg from "../etc/gangnamseatsvg";
import { Button, LinearProgress, Slider } from "@mui/joy";
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
import { each, set } from "lodash";
import DaechiSeatSvg from "../etc/daechiseatsvg";
import Daechi2SeatSvg from "../etc/daechi2seatsvg";
import Daechi3SeatSvg from "../etc/daechi3seatsvg";
import totalLocationData from "../../../data/locationdata";
import { renderSeatSvg } from "../../../data/locationFuntion";
import JobAttendance from "./jobattendance";
import { Switch } from "@mui/material";

type LocationKey = 'gangnam' | 'daechi' | 'daechi2' | 'daechi3';

const totalSeats : Record<any, number>= {
    gangnam: 75,
    daechi: 73,
    daechi2: 84,
    daechi3: 73
}

const color : Record<any, any> = {
    daechi : {
        blue : {
            start : 29,
            end  : 56
        },
        red : {
            start : 5,
            end : 28
        }
    },
    daechi2 : {
        blue : {
            start : 111,
            end : 136
        },
        red : {
            start : 137,
            end : 164
        }
    },
    daechi3 : {
        blue : {
            start : 237,
            end : 252
        },
        red : {
            start : 211,
            end : 236
        }
    }
}

const SeatListForWinter: React.FC<any> = (props) => {


    const [location, setLocation] = useState<any>("");
    const [sliderValue, setSliderValue] = useState(5);
    const ref = useRef(null);
    const [loading, setLoading] = useState(false);
    const [currentNumber, setCurrentNumber] = useState(0);

    //index가 200개 있는 state를 만든다.
    const [memo, setMemo] = useState(new Array(200).fill(""));
    const [update, setUpdate] = useState(0);
    const [update2, setUpdate2] = useState(0);
    const [hideName, setHideName] = useState(true);

    useEffect(() => {

        if (!props.user || !props.user.academy) {
            return;
        }

        const academy = props.user.academy;

        console.log(academy);


        const filteredLocationData = totalLocationData.filter((locationData) => {

            if (locationData.english === "total") {
                return false;
            }

            return locationData.academy.includes(academy);
        })

        const firstLocationData = filteredLocationData[0];

        console.log(firstLocationData);


        setLocation(firstLocationData.english);


    }, [props.user]);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setLocation(newValue);
    };

    const createChangeHandler = (seat: number, kind : "description1" | "description2") => (e: any) => {

        handleInputChange(e, seat, kind);

    }

    const handleInputChange = async (e: any, seat: number, kind : "description1" | "description2") => {


        const value = e.target.value;

        //console.log(value, seat);

        const data = {
            seat,
            location,
            value,
            kind
        }

        const response = await fetch("https://peetsunbae.com/dashboard/avatar/seatlistwintermemo", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })

        const result = await response.json();

        console.log(result);

        if (result.message === "success") {
            console.log("success");
            setUpdate(Math.random());
        }

    }

    useEffect(() => {

        if(!location){
            return;
        }

        getInfo2(location);

    }, [update])

    useEffect(() => {

        if (!location) {
            return;
        }

        setCurrentNumber(0);

        getInfo(location);
    }, [location, hideName]);

    const getJobInfo = async () => {

        try {

        } catch (e) {
            console.log(e);
        }

    }

    const getInfo2 = async (location: string) => {

        if (!location) {
            return;
        }

        if (location === "job") {
            getJobInfo();
            return;
        }

        try {

            const response = await fetch(`https://peetsunbae.com/dashboard/avatar/seatlistforwinter?location=${location}`, {
                method: "GET",
                credentials: "include"
            })

            const result = await response.json();

            console.log(result);

            if (result.message === "success") {

                const data = result.data;

                var count = 0;

                data.forEach((eachData : any) => {

                    if(eachData.seat && eachData.description1){
                        count++;
                    }

                })

                setCurrentNumber(count);

            }

        } catch (e) {
            console.log(e);
        }

    }


    const getInfo = async (location: string) => {

        if (!location) {
            return;
        }

        if (location === "job") {
            getJobInfo();
            return;
        }

        try {

            setLoading(true);

            const response = await fetch(`https://peetsunbae.com/dashboard/avatar/seatlistforwinter?location=${location}`, {
                method: "GET",
                credentials: "include"
            })

            const result = await response.json();

            console.log(result);

            if (result.message === "success") {
                setLoading(false);
                const data = result.data;
                drawSeat(data, location);

                var count = 0;

                data.forEach((eachData : any) => {

                    if(eachData.seat && eachData.description1){
                        count++;
                    }

                })

                setCurrentNumber(count);

            }

        } catch (e) {
            console.log(e);
        }

    }

    const drawSeat = (data: any[], location: string) => {

        const currentColor = color[location];

        const handleClick = (id: number) => () => {
        //            alert(`Clicked ID: ${id}`);
        };

        const locationInfo = totalLocationData.find((eachLocation) => eachLocation.english === location);

        var maxI = 76;

        if (location === "daechi2") {
            maxI = 200;
        }

        if (location === "daechi3") {
            maxI = 300;
        }

        maxI = 300;

        for (var i = 1; i < maxI; i++) {
            for (var j = 0; j < 3; j++) {

                var targetId = i * 10 + j;

                //console.log("before");
                //console.log(targetId);

                //console.log("after");
                //console.log(targetId);

                //console.log(targetId.toString());

                const element = document.getElementById(targetId.toString());
                //console.log(element);
                //console.log(element);

                if (!element) {
                    continue
                }

                element.addEventListener("click", handleClick(targetId));

                //element 밑에 있는 태그 path를 가져온다.
                const path = element.getElementsByTagName("path")[0];

                //path의 fill을 red로 바꾼다.


                //element밑에 있는 text를 가져온다.
                const text = element.getElementsByTagName("text")[0];
                //text의 text-anchor를 middle로 바꾼다.
                text.setAttribute("text-anchor", "middle");


                //element밑에 있는 tspan을 가져온다.
                const tspan = element.getElementsByTagName("tspan")[0];

                var name = "";

                //tspan의 글씨 크기를 바꾼다.
                tspan.setAttribute("font-size", "16px");

                if(currentColor){

                    if (i >= currentColor.blue.start && i <= currentColor.blue.end) {
                        tspan.setAttribute("fill", "blue");
                    }

                    if (i >= currentColor.red.start && i <= currentColor.red.end) {
                        tspan.setAttribute("fill", "rgb(254,121,104)");
                    }

                }

                if (targetId % 10 === 1) {

                    tspan.textContent = "";
                    const path = element.getElementsByTagName("path")[0];

                    //path의 transform 속성을 가져온다.
                    const transform = path.getAttribute("transform");

                    //transform의 형식은 translate(x, y)이다.
                    //x값을 가져온다.
                    var x : any = transform?.split(" ")[0].split("(")[1];
                    //y값을 가져온다.
                    var y : any = transform?.split(" ")[1].split(")")[0];

                    if(!x){
                        const bbox = path.getBBox();
                        x = bbox.x;
                    }

                    if(!y){
                        const bbox = path.getBBox();
                        y = bbox.y;
                    }

                    const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
                    foreignObject.setAttribute('x', x?.toString() || "");
                    foreignObject.setAttribute('y', y?.toString() || "");
                    foreignObject.setAttribute('width', '91.1');
                    foreignObject.setAttribute('height', '25.7');

                    // input 태그 생성
                    const input = document.createElement('input');
                    input.type = 'text';
                    input.style.width = '100%';
                    input.style.height = '100%';
                    input.style.border = 'none';
                    input.style.backgroundColor = 'transparent';
                    input.style.fontFamily = '맑은 고딕';
                    input.style.fontSize = '16px';
                    //input.style.color = '#C41C1C';
                    input.style.textAlign = 'center';
                    input.value = "";

                    // input.addEventListener("change", (e) => {
                    //     handleInputChange(e, i);
                    // });

                    data.forEach((eachData: any) => {

                        if (!eachData.seat) {
                            return;
                        }

                        if(!eachData.description1){
                            return;
                        }

                        if (i === +eachData.seat) {

                            const name = eachData.description1;

                            //name의 가운데 글자를 *로 바꾼다.
                            const splitedName = name.split("");
                            const firstChar = splitedName[0];
                            var lastChar = splitedName[splitedName.length - 1];
                            const middleChar = splitedName.slice(1, splitedName.length - 1).map((eachChar : string) => "*").join("");

                            if(name.length === 2){
                                lastChar = "*";
                            }

                            if(hideName){
                                input.value = firstChar + middleChar + lastChar;
                            }else{
                                input.value = name;
                            }

                            //input.value = eachData.description1;
                        }

                    })


                    input.addEventListener("change", createChangeHandler(i, "description1"));
                    foreignObject.appendChild(input);
                    element.appendChild(foreignObject);

                }


                //tsapn의 attribute중 x를 값을 바꾼다.
                //27.5가 정중앙
                tspan.setAttribute("x", "45.5");
                //tspan.setAttribute("y", "18.7");
                tspan.setAttribute("y", "19.2");


                if (targetId % 10 === 2) {

                    tspan.textContent = "";
                    const path = element.getElementsByTagName("path")[0];

                    //path의 transform 속성을 가져온다.
                    const transform = path.getAttribute("transform");

                    //transform의 형식은 translate(x, y)이다.
                    //x값을 가져온다.
                    const x = transform?.split(" ")[0].split("(")[1];
                    //y값을 가져온다.
                    const y = transform?.split(" ")[1].split(")")[0];

                    const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
                    foreignObject.setAttribute('x', x?.toString() || "");
                    foreignObject.setAttribute('y', y?.toString() || "");
                    foreignObject.setAttribute('width', '91.1');
                    foreignObject.setAttribute('height', '25.7');

                    // input 태그 생성
                    const input = document.createElement('input');
                    input.type = 'text';
                    input.style.width = '100%';
                    input.style.height = '100%';
                    input.style.border = 'none';
                    input.style.backgroundColor = 'transparent';
                    input.style.fontFamily = '맑은 고딕';
                    input.style.fontSize = '16px';
                    //input.style.color = '#C41C1C';
                    input.style.textAlign = 'center';
                    input.value = "";

                    // input.addEventListener("change", (e) => {
                    //     handleInputChange(e, i);
                    // });

                    data.forEach((eachData: any) => {
                        if (!eachData.seat) {
                            return;
                        }

                        if (i === +eachData.seat) {
                            input.value = eachData.description2;
                        }

                    })


                    input.addEventListener("change", createChangeHandler(i, "description2"));
                    foreignObject.appendChild(input);
                    element.appendChild(foreignObject);


                }

            }
        }

    }


    return (
        <div>
            <div style={{ width: "1200px", margin: "0 auto" }}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={location}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                                {
                                    (props.user && props.user.academy) && totalLocationData.map((locationData, index) => {

                                        if (locationData.english === "total") {
                                            return;
                                        }

                                        if (!locationData.academy.includes(props.user.academy)) {
                                            return;
                                        }

                                        return (
                                            <Tab label={<span style={{ fontFamily: "Apple_SB" }}>{locationData.korean}</span>} value={locationData.english} key={index} />
                                        )

                                    })
                                }
                            </TabList>
                        </Box>
                    </TabContext>
                </Box>
            </div>

            {
                location !== "job" &&
                <>
                    <div style={{ width: "1200px", display: "flex", justifyContent: "flex-end", margin: "0 auto", marginTop: "12px" }}>
                        <div>
                            <ReactToPrint
                                onAfterPrint={() => {
                                    console.log("printed");
                                }}
                                trigger={() => {
                                    return (
                                        <Button size="lg" variant='soft' color='neutral'>
                                            <PrintIcon />
                                        </Button>
                                    )
                                }
                                }
                                content={() => ref.current}
                            />
                        </div>
                    </div>



                    <div style={{ width: "1200px", display: "flex", justifyContent: "flex-end", margin: "0 auto" }}>
                        <div style={{ width: "200px", marginTop: "24px" }}>
                            <Slider
                                variant="soft"
                                size="lg"
                                color="neutral"
                                min={3}
                                max={7}
                                value={sliderValue}
                                onChange={(e, value) => {

                                    if (!ref.current) {
                                        return;
                                    }

                                    const element = ref.current as any;

                                    //value가 number가 아니면 return
                                    if (typeof value !== "number") {
                                        return;
                                    }
                                    //element width를 바꾼다.
                                    if (value) {
                                        element.style.width = `${(value + 5) * 100}px`;
                                    }

                                    console.log(value);
                                    setSliderValue(value as number);
                                }}
                            />
                        </div>
                    </div>

                    <div style={{
                        display : "flex",
                        justifyContent : "flex-end",
                        width: "1200px",
                        margin: "0 auto",
                        fontSize: "18px",
                        fontWeight : 600,
                        marginTop : "24px"
                    }}>
                        남은 자리 :&nbsp;
                        {
                            (location && totalSeats[location]) && 
                           totalSeats[location] - currentNumber
                        } / 
                        &nbsp;
                        {
                            (location && totalSeats[location]) && 
                            totalSeats[location]
                        }
                    </div>
                    <div style={{
                        display : "flex",
                        justifyContent : "flex-end",
                        width: "1200px",
                        margin: "0 auto",
                        fontSize: "18px",
                        fontWeight : 600,
                        marginTop : "24px",
                        alignItems : "center"
                    }}>
                        이름 가리기 : 
                        <Switch 
                            checked={hideName}
                            onChange={(e) => {
                                setHideName(e.target.checked);
                            }}
                        />
                    </div>
                </>
            }



            {
                loading ?
                    <div style={{ width: "1200px", margin: "0 auto", marginTop: "30px" }}>
                        <LinearProgress color="neutral" />
                    </div>
                    :
                    location !== "job" ?
                        <div ref={ref} style={{ width: "1200px", margin: "0 auto", marginTop: "0px" }}>
                            {
                                (props.user && props.user.academy) &&
                                renderSeatSvg(location, props.user)
                            }
                        </div>
                        :
                        <div>
                            <JobAttendance user={props.user} />
                        </div>
            }




        </div>
    );

}

export default SeatListForWinter;