import * as React from "react";

function MolipSeatSvg(){
  return (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1200"
    height="1600"
    viewBox="0 0 1200 1600"
  >
    <g id="900" transform="translate(5.5 5.5)">
      <path fill="none" d="M0 0h1587v2245H0z"></path>
      <g id="shape1">
        <path fill="#7e7e7e" d="M204.508 110.662v385.5h7.6v-385.5h-7.6"></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M204.508 110.662v385.5m7.6 0v-385.5m-7.6 0h7.6m-7.6 385.5h7.6"
        ></path>
      </g>
      <g id="900">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M702.436 1020.971h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(702.436 1020.971)"
        >
          <tspan x="53.1" y="17.3">
            90
          </tspan>
        </text>
      </g>
      <g id="901">
        <path
          fill="#fff"
          stroke="#323232"
          d="M702.436 1046.67h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(702.436 1046.67)"
        >
          <tspan x="48.1" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="902">
        <path
          fill="#fff"
          stroke="#323232"
          d="M702.436 1072.37h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(702.436 1072.37)"
        >
          <tspan x="48.1" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="880">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M457.884 1020.971h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(457.884 1020.971)"
        >
          <tspan x="53.1" y="17.3">
            88
          </tspan>
        </text>
      </g>
      <g id="881">
        <path
          fill="#fff"
          stroke="#323232"
          d="M457.884 1046.67h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(457.884 1046.67)"
        >
          <tspan x="48.1" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="882">
        <path
          fill="#fff"
          stroke="#323232"
          d="M457.884 1072.37h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(457.884 1072.37)"
        >
          <tspan x="48.1" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="890">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M580.152 1020.971h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(580.152 1020.971)"
        >
          <tspan x="53.1" y="17.3">
            89
          </tspan>
        </text>
      </g>
      <g id="891">
        <path
          fill="#fff"
          stroke="#323232"
          d="M580.152 1046.67h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(580.152 1046.67)"
        >
          <tspan x="48.1" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="892">
        <path
          fill="#fff"
          stroke="#323232"
          d="M580.152 1072.37h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(580.152 1072.37)"
        >
          <tspan x="48.1" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="910">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M824.706 1020.971h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(824.706 1020.971)"
        >
          <tspan x="53.1" y="17.3">
            91
          </tspan>
        </text>
      </g>
      <g id="911">
        <path
          fill="#fff"
          stroke="#323232"
          d="M824.706 1046.67h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(824.706 1046.67)"
        >
          <tspan x="48.1" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="912">
        <path
          fill="#fff"
          stroke="#323232"
          d="M824.706 1072.37h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(824.706 1072.37)"
        >
          <tspan x="48.1" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="920">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M946.976 1020.97h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(946.976 1020.97)"
        >
          <tspan x="53.1" y="17.3">
            92
          </tspan>
        </text>
      </g>
      <g id="921">
        <path
          fill="#fff"
          stroke="#323232"
          d="M946.976 1046.67h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(946.976 1046.67)"
        >
          <tspan x="48.1" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="922">
        <path
          fill="#fff"
          stroke="#323232"
          d="M946.976 1072.37h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(946.976 1072.37)"
        >
          <tspan x="48.1" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="940">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M659.159 1144.253h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(659.159 1144.253)"
        >
          <tspan x="43.4" y="17.3">
            94
          </tspan>
        </text>
      </g>
      <g id="941">
        <path
          fill="#fff"
          stroke="#323232"
          d="M659.159 1169.953h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(659.159 1169.953)"
        >
          <tspan x="38.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="942">
        <path
          fill="#fff"
          stroke="#323232"
          d="M659.159 1195.653h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(659.159 1195.653)"
        >
          <tspan x="38.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="950">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M659.148 1221.353h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(659.148 1221.353)"
        >
          <tspan x="43.4" y="17.3">
            95
          </tspan>
        </text>
      </g>
      <g id="951">
        <path
          fill="#fff"
          stroke="#323232"
          d="M659.148 1247.053h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(659.148 1247.053)"
        >
          <tspan x="38.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="952">
        <path
          fill="#fff"
          stroke="#323232"
          d="M659.148 1272.753h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(659.148 1272.753)"
        >
          <tspan x="38.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape23">
        <path
          fill="#7e7e7e"
          d="M1069.586 1421.575h-856.7l-3.7 3.8-3.8 3.8h871.8l-3.8-3.8z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M1069.586 1421.575h-856.7m-7.5 7.6h871.8"
        ></path>
      </g>
      <g id="shape24">
        <path
          fill="#ebebeb"
          stroke="#323232"
          d="M114.21 548.689h91.1v77.1h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="14.667"
          fontWeight="bold"
          transform="translate(114.21 548.689)"
        >
          <tspan x="8" y="45">
            인포데스크
          </tspan>
        </text>
      </g>
      <g id="870">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M335.61 1020.971h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(335.61 1020.971)"
        >
          <tspan x="53.1" y="17.3">
            87
          </tspan>
        </text>
      </g>
      <g id="871">
        <path
          fill="#fff"
          stroke="#323232"
          d="M335.61 1046.67h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(335.61 1046.67)"
        >
          <tspan x="48.1" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="872">
        <path
          fill="#fff"
          stroke="#323232"
          d="M335.61 1072.37h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(335.61 1072.37)"
        >
          <tspan x="48.1" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="860">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M213.34 1020.971h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(213.34 1020.971)"
        >
          <tspan x="53.1" y="17.5">
            86
          </tspan>
        </text>
      </g>
      <g id="861">
        <path
          fill="#fff"
          stroke="#323232"
          d="M213.34 1046.67h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(213.34 1046.67)"
        >
          <tspan x="48.1" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="862">
        <path
          fill="#fff"
          stroke="#323232"
          d="M213.34 1072.37h122.3v25.7h-122.3z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(213.34 1072.37)"
        >
          <tspan x="48.1" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="1050">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M212.932 1144.254h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(212.932 1144.254)"
        >
          <tspan x="39.4" y="17.3">
            105
          </tspan>
        </text>
      </g>
      <g id="1051">
        <path
          fill="#fff"
          stroke="#323232"
          d="M212.932 1169.953h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.932 1169.953)"
        >
          <tspan x="38.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="1052">
        <path
          fill="#fff"
          stroke="#323232"
          d="M212.932 1195.652h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.932 1195.652)"
        >
          <tspan x="38.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="1040">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M315.67 1144.254h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(315.67 1144.254)"
        >
          <tspan x="39.4" y="17.3">
            104
          </tspan>
        </text>
      </g>
      <g id="1041">
        <path
          fill="#fff"
          stroke="#323232"
          d="M315.67 1169.953h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(315.67 1169.953)"
        >
          <tspan x="38.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="1042">
        <path
          fill="#fff"
          stroke="#323232"
          d="M315.67 1195.652h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(315.67 1195.652)"
        >
          <tspan x="38.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="1030">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M418.406 1144.253h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(418.406 1144.253)"
        >
          <tspan x="39.4" y="17.3">
            103
          </tspan>
        </text>
      </g>
      <g id="1031">
        <path
          fill="#fff"
          stroke="#323232"
          d="M418.406 1169.953h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(418.406 1169.953)"
        >
          <tspan x="38.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="1032">
        <path
          fill="#fff"
          stroke="#323232"
          d="M418.406 1195.653h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(418.406 1195.653)"
        >
          <tspan x="38.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape40">
        <path
          fill="#7e7e7e"
          d="M955.83 102.712h-850.3l3.8 3.8 3.8 3.8h839.9l1.4-3.8z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M955.83 102.712h-850.3m7.6 7.6h839.9"
        ></path>
      </g>
      <g id="shape41">
        <path
          fill="#d7d7d7"
          stroke="#323232"
          d="M213.629 1221.74h307.5v199.6h-307.5z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(213.629 1221.74)"
        >
          <tspan x="130.8" y="95.8">
            Talking
          </tspan>
          <tspan x="140.3" y="112.8">
            Cafe
          </tspan>
        </text>
      </g>
      <g id="930">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M761.926 1144.255h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.926 1144.255)"
        >
          <tspan x="43.4" y="17.3">
            93
          </tspan>
        </text>
      </g>
      <g id="931">
        <path
          fill="#fff"
          stroke="#323232"
          d="M761.926 1169.955h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.926 1169.955)"
        >
          <tspan x="38.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="932">
        <path
          fill="#fff"
          stroke="#323232"
          d="M761.926 1195.655h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.926 1195.655)"
        >
          <tspan x="38.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="3000">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M864.948 1144.254h204.9v25.7h-204.9z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(864.948 1144.254)"
        >
          <tspan x="90.5" y="17.3">
            300
          </tspan>
        </text>
      </g>
      <g id="3001">
        <path
          fill="#fff"
          stroke="#323232"
          d="M864.948 1169.954h204.9v25.7h-204.9z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(864.948 1169.954)"
        >
          <tspan x="89.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="3002">
        <path
          fill="#fff"
          stroke="#323232"
          d="M864.948 1195.654h204.9v25.7h-204.9z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(864.948 1195.654)"
        >
          <tspan x="89.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="960">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M761.914 1221.354h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.914 1221.354)"
        >
          <tspan x="43.4" y="17.3">
            96
          </tspan>
        </text>
      </g>
      <g id="961">
        <path
          fill="#fff"
          stroke="#323232"
          d="M761.914 1247.054h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.914 1247.054)"
        >
          <tspan x="38.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="962">
        <path
          fill="#fff"
          stroke="#323232"
          d="M761.914 1272.754h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.914 1272.754)"
        >
          <tspan x="38.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="970">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M864.647 1221.354h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(864.647 1221.354)"
        >
          <tspan x="43.4" y="17.3">
            97
          </tspan>
        </text>
      </g>
      <g id="971">
        <path
          fill="#fff"
          stroke="#323232"
          d="M864.647 1247.054h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(864.647 1247.054)"
        >
          <tspan x="38.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="972">
        <path
          fill="#fff"
          stroke="#323232"
          d="M864.647 1272.754h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(864.647 1272.754)"
        >
          <tspan x="38.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="980">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M967.391 1221.355h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(967.391 1221.355)"
        >
          <tspan x="43.4" y="17.3">
            98
          </tspan>
        </text>
      </g>
      <g id="981">
        <path
          fill="#fff"
          stroke="#323232"
          d="M967.391 1247.055h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(967.391 1247.055)"
        >
          <tspan x="38.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="982">
        <path
          fill="#fff"
          stroke="#323232"
          d="M967.391 1272.755h102.7v25.7h-102.7z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(967.391 1272.755)"
        >
          <tspan x="38.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape57">
        <path
          fill="#7e7e7e"
          d="m1075.117 1012.331-214.7-.017-3.8 3.8-3.8-3.8-639.9-.052-3.8 3.8 3.8 3.8 862.2.07z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="m1075.117 1012.331-862.2-.069m0 7.6 862.2.07m0-7.6v7.6"
        ></path>
      </g>
      <g id="1010">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M659.248 1344.218h136.8v25.7h-136.8z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(659.248 1344.218)"
        >
          <tspan x="56.4" y="17.3">
            101
          </tspan>
        </text>
      </g>
      <g id="1011">
        <path
          fill="#fff"
          stroke="#323232"
          d="M659.248 1369.918h136.8v25.7h-136.8z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(659.248 1369.918)"
        >
          <tspan x="55.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="1012">
        <path
          fill="#fff"
          stroke="#323232"
          d="M659.248 1395.618h136.8v25.7h-136.8z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(659.248 1395.618)"
        >
          <tspan x="55.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="1000">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M796.063 1344.218h136.8v25.7h-136.8z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(796.063 1344.218)"
        >
          <tspan x="56.4" y="17.3">
            100
          </tspan>
        </text>
      </g>
      <g id="1001">
        <path
          fill="#fff"
          stroke="#323232"
          d="M796.063 1369.918h136.8v25.7h-136.8z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(796.063 1369.918)"
        >
          <tspan x="55.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="1002">
        <path
          fill="#fff"
          stroke="#323232"
          d="M796.063 1395.618h136.8v25.7h-136.8z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(796.063 1395.618)"
        >
          <tspan x="55.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="990">
        <path
          fill="#d0f0ea"
          stroke="#323232"
          d="M932.832 1344.218h136.8v25.7h-136.8z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(932.832 1344.218)"
        >
          <tspan x="60.4" y="17.3">
            99
          </tspan>
        </text>
      </g>
      <g id="991">
        <path
          fill="#fff"
          stroke="#323232"
          d="M932.832 1369.918h136.8v25.7h-136.8z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(932.832 1369.918)"
        >
          <tspan x="55.4" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="992">
        <path
          fill="#fff"
          stroke="#323232"
          d="M932.832 1395.618h136.8v25.7h-136.8z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(932.832 1395.618)"
        >
          <tspan x="55.4" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="1020">
        <path
          fill="#d0f0ea"
          stroke="#101843"
          d="M520.66 1344.218h138.4v25.7h-138.4z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(520.66 1344.218)"
        >
          <tspan x="57.2" y="17.3">
            102
          </tspan>
        </text>
      </g>
      <g id="1021">
        <path
          fill="#fff"
          stroke="#101843"
          d="M520.66 1369.918h138.4v25.7h-138.4z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(520.66 1369.918)"
        >
          <tspan x="56.2" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="1022">
        <path
          fill="#fff"
          stroke="#101843"
          d="M520.66 1395.618h138.4v25.7h-138.4z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(520.66 1395.618)"
        >
          <tspan x="56.2" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="20">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M113.4 341.974h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(113.4 341.974)"
        >
          <tspan x="41.5" y="17.5">
            2
          </tspan>
        </text>
      </g>
      <g id="21">
        <path
          fill="#fff"
          stroke="#101843"
          d="M113.4 367.674h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(113.4 367.674)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="22">
        <path
          fill="#fff"
          stroke="#101843"
          d="M113.4 393.374h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(113.4 393.374)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="10">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M113.4 419.074h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(113.4 419.074)"
        >
          <tspan x="41.5" y="17.5">
            1
          </tspan>
        </text>
      </g>
      <g id="11">
        <path
          fill="#fff"
          stroke="#101843"
          d="M113.4 444.774h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(113.4 444.774)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="12">
        <path
          fill="#fff"
          stroke="#101843"
          d="M113.4 470.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(113.4 470.474)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="40">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M113.21 187.774h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(113.21 187.774)"
        >
          <tspan x="41.5" y="17.5">
            4
          </tspan>
        </text>
      </g>
      <g id="41">
        <path
          fill="#fff"
          stroke="#101843"
          d="M113.21 213.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(113.21 213.474)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="42">
        <path
          fill="#fff"
          stroke="#101843"
          d="M113.21 239.174h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(113.21 239.174)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="30">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M113.21 264.874h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(113.21 264.874)"
        >
          <tspan x="41.5" y="17.5">
            3
          </tspan>
        </text>
      </g>
      <g id="31">
        <path
          fill="#fff"
          stroke="#101843"
          d="M113.21 290.574h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(113.21 290.574)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="32">
        <path
          fill="#fff"
          stroke="#101843"
          d="M113.21 316.274h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(113.21 316.274)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="50">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M113.21 110.674h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(113.21 110.674)"
        >
          <tspan x="41.5" y="17.5">
            5
          </tspan>
        </text>
      </g>
      <g id="51">
        <path
          fill="#fff"
          stroke="#101843"
          d="M113.21 136.374h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(113.21 136.374)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="52">
        <path
          fill="#fff"
          stroke="#101843"
          d="M113.21 162.074h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(113.21 162.074)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="70">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M212.227 341.974h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(212.227 341.974)"
        >
          <tspan x="41.5" y="17.5">
            7
          </tspan>
        </text>
      </g>
      <g id="71">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.227 367.674h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.227 367.674)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="72">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.227 393.374h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.227 393.374)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="60">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M212.227 419.074h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(212.227 419.074)"
        >
          <tspan x="41.5" y="17.5">
            6
          </tspan>
        </text>
      </g>
      <g id="61">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.227 444.774h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.227 444.774)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="62">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.227 470.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.227 470.474)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="90">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M212.037 187.774h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(212.037 187.774)"
        >
          <tspan x="41.5" y="17.5">
            9
          </tspan>
        </text>
      </g>
      <g id="91">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.037 213.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.037 213.474)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="92">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.037 239.174h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.037 239.174)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="80">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M212.037 264.874h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(212.037 264.874)"
        >
          <tspan x="41.5" y="17.5">
            8
          </tspan>
        </text>
      </g>
      <g id="81">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.037 290.574h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.037 290.574)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="82">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.037 316.274h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.037 316.274)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="100">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M212.037 110.674h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(212.037 110.674)"
        >
          <tspan x="37.5" y="17.5">
            10
          </tspan>
        </text>
      </g>
      <g id="101">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.037 136.374h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.037 136.374)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="102">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.037 162.074h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.037 162.074)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape100">
        <path fill="#7e7e7e" d="M420.8 110.662v385h7.6v-385h-7.6"></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M420.8 110.662v385m7.6 0v-385m-7.6 0h7.6m-7.6 385h7.6"
        ></path>
      </g>
      <g id="160">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M428.55 341.365h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(428.55 341.365)"
        >
          <tspan x="37.5" y="17.5">
            16
          </tspan>
        </text>
      </g>
      <g id="161">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.55 367.065h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.55 367.065)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="162">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.55 392.765h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.55 392.765)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="150">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M428.55 418.465h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(428.55 418.465)"
        >
          <tspan x="37.5" y="17.5">
            15
          </tspan>
        </text>
      </g>
      <g id="151">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.55 444.165h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.55 444.165)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="152">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.55 469.865h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.55 469.865)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="180">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M428.36 187.165h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(428.36 187.165)"
        >
          <tspan x="37.5" y="17.5">
            18
          </tspan>
        </text>
      </g>
      <g id="181">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.36 212.865h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.36 212.865)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="182">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.36 238.565h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.36 238.565)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="170">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M428.36 264.265h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(428.36 264.265)"
        >
          <tspan x="37.5" y="17.5">
            17
          </tspan>
        </text>
      </g>
      <g id="171">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.36 289.965h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.36 289.965)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="172">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.36 315.665h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.36 315.665)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="190">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M428.36 110.065h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(428.36 110.065)"
        >
          <tspan x="37.5" y="17.5">
            19
          </tspan>
        </text>
      </g>
      <g id="191">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.36 135.765h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.36 135.765)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="192">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.36 161.465h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.36 161.465)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="230">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M545.331 341.467h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(545.331 341.467)"
        >
          <tspan x="37.5" y="17.5">
            23
          </tspan>
        </text>
      </g>
      <g id="231">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.331 367.167h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.331 367.167)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="232">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.331 392.867h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.331 392.867)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="240">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M545.331 418.567h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(545.331 418.567)"
        >
          <tspan x="37.5" y="17.5">
            24
          </tspan>
        </text>
      </g>
      <g id="241">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.331 444.267h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.331 444.267)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="242">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.331 469.967h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.331 469.967)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="210">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M545.141 187.267h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(545.141 187.267)"
        >
          <tspan x="37.5" y="17.5">
            21
          </tspan>
        </text>
      </g>
      <g id="211">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.141 212.967h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.141 212.967)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="212">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.141 238.667h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.141 238.667)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="220">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M545.141 264.367h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(545.141 264.367)"
        >
          <tspan x="37.5" y="17.5">
            22
          </tspan>
        </text>
      </g>
      <g id="221">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.141 290.067h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.141 290.067)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="222">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.141 315.767h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.141 315.767)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="200">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M545.141 110.167h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(545.141 110.167)"
        >
          <tspan x="37.5" y="17.5">
            20
          </tspan>
        </text>
      </g>
      <g id="201">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.141 135.867h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.141 135.867)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="202">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.141 161.567h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.141 161.567)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape131">
        <path fill="#7e7e7e" d="M636.734 110.268v385h7.6v-385h-7.6"></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M636.734 110.268v385m7.6 0v-385m-7.6 0h7.6m-7.6 385h7.6"
        ></path>
      </g>
      <g id="260">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M644.483 341.97h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(644.483 341.97)"
        >
          <tspan x="37.5" y="17.5">
            26
          </tspan>
        </text>
      </g>
      <g id="261">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.483 367.67h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.483 367.67)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="262">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.483 393.37h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.483 393.37)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="250">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M644.483 419.07h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(644.483 419.07)"
        >
          <tspan x="37.5" y="17.5">
            25
          </tspan>
        </text>
      </g>
      <g id="251">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.483 444.77h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.483 444.77)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="252">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.483 470.47h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.483 470.47)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="280">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M644.293 187.77h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(644.293 187.77)"
        >
          <tspan x="37.5" y="17.5">
            28
          </tspan>
        </text>
      </g>
      <g id="281">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.293 213.47h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.293 213.47)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="282">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.293 239.17h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.293 239.17)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="270">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M644.293 264.87h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(644.293 264.87)"
        >
          <tspan x="37.5" y="17.5">
            27
          </tspan>
        </text>
      </g>
      <g id="271">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.293 290.57h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.293 290.57)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="272">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.293 316.27h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.293 316.27)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="290">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M644.293 110.67h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(644.293 110.67)"
        >
          <tspan x="37.5" y="17.5">
            29
          </tspan>
        </text>
      </g>
      <g id="291">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.293 136.37h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.293 136.37)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="292">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.293 162.07h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.293 162.07)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="330">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M761.265 341.964h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.265 341.964)"
        >
          <tspan x="37.5" y="17.5">
            33
          </tspan>
        </text>
      </g>
      <g id="331">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.265 367.664h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.265 367.664)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="332">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.265 393.364h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.265 393.364)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="340">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M761.265 419.064h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.265 419.064)"
        >
          <tspan x="37.5" y="17.5">
            34
          </tspan>
        </text>
      </g>
      <g id="341">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.265 444.764h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.265 444.764)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="342">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.265 470.464h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.265 470.464)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="310">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M761.075 187.764h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.075 187.764)"
        >
          <tspan x="37.5" y="17.5">
            31
          </tspan>
        </text>
      </g>
      <g id="311">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.075 213.464h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.075 213.464)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="312">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.075 239.164h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.075 239.164)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="320">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M761.075 264.864h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.075 264.864)"
        >
          <tspan x="37.5" y="17.5">
            32
          </tspan>
        </text>
      </g>
      <g id="321">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.075 290.564h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.075 290.564)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="322">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.075 316.264h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.075 316.264)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="300">
        <path
          fill="#dbe8ff"
          stroke="#101843"
          d="M761.075 110.664h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.075 110.664)"
        >
          <tspan x="37.5" y="17.5">
            30
          </tspan>
        </text>
      </g>
      <g id="301">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.075 136.364h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.075 136.364)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="302">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.075 162.064h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.075 162.064)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape162">
        <path fill="#7e7e7e" d="M852.668 110.765v385h7.6v-385h-7.6"></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M852.668 110.765v385m7.6 0v-385m-7.6 0h7.6m-7.6 385h7.6"
        ></path>
      </g>
      <g id="360">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M860.417 342.467h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(860.417 342.467)"
        >
          <tspan x="37.5" y="17.5">
            36
          </tspan>
        </text>
      </g>
      <g id="361">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.417 368.167h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.417 368.167)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="362">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.417 393.867h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.417 393.867)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="350">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M860.417 419.567h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(860.417 419.567)"
        >
          <tspan x="37.5" y="17.5">
            35
          </tspan>
        </text>
      </g>
      <g id="351">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.417 445.267h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.417 445.267)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="352">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.417 470.967h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.417 470.967)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="380">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M860.227 188.267h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(860.227 188.267)"
        >
          <tspan x="37.5" y="17.5">
            38
          </tspan>
        </text>
      </g>
      <g id="381">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.227 213.967h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.227 213.967)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="382">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.227 239.667h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.227 239.667)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="370">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M860.227 265.367h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(860.227 265.367)"
        >
          <tspan x="37.5" y="17.5">
            37
          </tspan>
        </text>
      </g>
      <g id="371">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.227 291.067h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.227 291.067)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="372">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.227 316.767h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.227 316.767)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="390">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M860.227 111.167h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(860.227 111.167)"
        >
          <tspan x="37.5" y="17.5">
            39
          </tspan>
        </text>
      </g>
      <g id="391">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.227 136.867h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.227 136.867)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="392">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.227 162.567h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.227 162.567)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape178">
        <path
          fill="#7e7e7e"
          d="M852.824 548.977v463.3l3.8 3.8 3.8-3.8v-463.3h-7.6"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M852.824 548.977v463.3m7.6 0v-463.3m-7.6 0h7.6"
        ></path>
      </g>
      <g id="2000">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M860.418 935.178h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(860.418 935.178)"
        >
          <tspan x="33.5" y="17.5">
            200
          </tspan>
        </text>
      </g>
      <g id="2001">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.418 960.878h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.418 960.878)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="2002">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.418 986.578h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.418 986.578)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="490">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M860.417 676.177h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(860.417 676.177)"
        >
          <tspan x="37.5" y="17.5">
            49
          </tspan>
        </text>
      </g>
      <g id="491">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.417 701.877h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.417 701.877)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="492">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.417 727.577h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.417 727.577)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="480">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M860.418 806.41h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(860.418 806.41)"
        >
          <tspan x="37.5" y="17.5">
            48
          </tspan>
        </text>
      </g>
      <g id="481">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.418 832.11h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.418 832.11)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="482">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.418 857.81h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.418 857.81)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="500">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M860.418 548.683h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(860.418 548.683)"
        >
          <tspan x="37.5" y="17.5">
            50
          </tspan>
        </text>
      </g>
      <g id="501">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.418 574.383h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.418 574.383)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="502">
        <path
          fill="#fff"
          stroke="#101843"
          d="M860.418 600.083h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(860.418 600.083)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="660">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M545.901 780.48h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(545.901 780.48)"
        >
          <tspan x="37.5" y="17.5">
            66
          </tspan>
        </text>
      </g>
      <g id="661">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.901 806.18h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.901 806.18)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="662">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.901 831.88h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.901 831.88)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="670">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M545.901 857.58h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(545.901 857.58)"
        >
          <tspan x="37.5" y="17.5">
            67
          </tspan>
        </text>
      </g>
      <g id="671">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.901 883.28h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.901 883.28)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="672">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.901 908.98h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.901 908.98)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="640">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M545.711 626.28h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(545.711 626.28)"
        >
          <tspan x="37.5" y="17.5">
            64
          </tspan>
        </text>
      </g>
      <g id="641">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.711 651.98h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.711 651.98)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="642">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.711 677.68h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.711 677.68)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="650">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M545.711 703.38h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(545.711 703.38)"
        >
          <tspan x="37.5" y="17.5">
            65
          </tspan>
        </text>
      </g>
      <g id="651">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.711 729.08h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.711 729.08)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="652">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.711 754.78h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.711 754.78)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="630">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M545.711 549.18h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(545.711 549.18)"
        >
          <tspan x="37.5" y="17.5">
            63
          </tspan>
        </text>
      </g>
      <g id="631">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.711 574.88h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.711 574.88)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="632">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.711 600.58h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.711 600.58)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape206">
        <path fill="#7e7e7e" d="M637.303 548.032v463.3h7.6v-463.3h-7.6"></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M637.303 548.032v463.3m7.6 0v-463.3m-7.6 0h7.6m-7.6 463.3h7.6"
        ></path>
      </g>
      <g id="590">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M645.051 779.983h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(645.051 779.983)"
        >
          <tspan x="37.5" y="17.5">
            59
          </tspan>
        </text>
      </g>
      <g id="591">
        <path
          fill="#fff"
          stroke="#101843"
          d="M645.051 805.683h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(645.051 805.683)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="592">
        <path
          fill="#fff"
          stroke="#101843"
          d="M645.051 831.383h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(645.051 831.383)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="580">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M645.051 857.083h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(645.051 857.083)"
        >
          <tspan x="37.5" y="17.5">
            58
          </tspan>
        </text>
      </g>
      <g id="581">
        <path
          fill="#fff"
          stroke="#101843"
          d="M645.051 882.783h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(645.051 882.783)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="582">
        <path
          fill="#fff"
          stroke="#101843"
          d="M645.051 908.483h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(645.051 908.483)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="610">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M644.861 625.783h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(644.861 625.783)"
        >
          <tspan x="37.5" y="17.5">
            61
          </tspan>
        </text>
      </g>
      <g id="611">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.861 651.483h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.861 651.483)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="612">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.861 677.183h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.861 677.183)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="600">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M644.861 702.883h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(644.861 702.883)"
        >
          <tspan x="37.5" y="17.5">
            60
          </tspan>
        </text>
      </g>
      <g id="601">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.861 728.583h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.861 728.583)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="602">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.861 754.283h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.861 754.283)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="620">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M644.861 548.683h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(644.861 548.683)"
        >
          <tspan x="37.5" y="17.5">
            62
          </tspan>
        </text>
      </g>
      <g id="621">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.861 574.383h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.861 574.383)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="622">
        <path
          fill="#fff"
          stroke="#101843"
          d="M644.861 600.083h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(644.861 600.083)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="680">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M545.901 935.182h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(545.901 935.182)"
        >
          <tspan x="37.5" y="17.5">
            68
          </tspan>
        </text>
      </g>
      <g id="681">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.901 960.878h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.901 960.878)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="682">
        <path
          fill="#fff"
          stroke="#101843"
          d="M545.901 986.578h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(545.901 986.578)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="570">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M645.051 934.685h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(645.051 934.685)"
        >
          <tspan x="37.5" y="17.5">
            57
          </tspan>
        </text>
      </g>
      <g id="571">
        <path
          fill="#fff"
          stroke="#101843"
          d="M645.051 960.388h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(645.051 960.388)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="572">
        <path
          fill="#fff"
          stroke="#101843"
          d="M645.051 986.088h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(645.051 986.088)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="780">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M329.59 779.983h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(329.59 779.983)"
        >
          <tspan x="37.5" y="17.5">
            78
          </tspan>
        </text>
      </g>
      <g id="781">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.59 805.683h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.59 805.683)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="782">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.59 831.383h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.59 831.383)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="790">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M329.59 857.083h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(329.59 857.083)"
        >
          <tspan x="37.5" y="17.5">
            79
          </tspan>
        </text>
      </g>
      <g id="791">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.59 882.783h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.59 882.783)"
        >
          <tspan x="32.5" y="17.5">
            이름
          </tspan>
        </text>
      </g>
      <g id="792">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.59 908.483h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.59 908.483)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="760">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M329.4 625.783h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(329.4 625.783)"
        >
          <tspan x="37.5" y="17.5">
            76
          </tspan>
        </text>
      </g>
      <g id="761">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.4 651.483h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.4 651.483)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="762">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.4 677.183h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.4 677.183)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="770">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M329.4 702.883h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(329.4 702.883)"
        >
          <tspan x="37.5" y="17.5">
            77
          </tspan>
        </text>
      </g>
      <g id="771">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.4 728.583h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.4 728.583)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="772">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.4 754.283h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.4 754.283)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="750">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M329.4 548.683h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(329.4 548.683)"
        >
          <tspan x="37.5" y="17.5">
            75
          </tspan>
        </text>
      </g>
      <g id="751">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.4 574.383h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.4 574.383)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="752">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.4 600.083h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.4 600.083)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape243">
        <path fill="#7e7e7e" d="M420.991 548.032v463.8h7.6v-463.8h-7.6"></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M420.991 548.032v463.8m7.6 0v-463.8m-7.6 0h7.6m-7.6 463.8h7.6"
        ></path>
      </g>
      <g id="710">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M428.74 780.486h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(428.74 780.486)"
        >
          <tspan x="37.5" y="17.5">
            71
          </tspan>
        </text>
      </g>
      <g id="711">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.74 806.186h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.74 806.186)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="712">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.74 831.886h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.74 831.886)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="700">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M428.74 857.586h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(428.74 857.586)"
        >
          <tspan x="37.5" y="17.5">
            70
          </tspan>
        </text>
      </g>
      <g id="701">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.74 883.286h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.74 883.286)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="702">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.74 908.986h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.74 908.986)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="730">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M428.55 626.286h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(428.55 626.286)"
        >
          <tspan x="37.5" y="17.5">
            73
          </tspan>
        </text>
      </g>
      <g id="731">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.55 651.986h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.55 651.986)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="732">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.55 677.686h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.55 677.686)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="720">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M428.55 703.386h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(428.55 703.386)"
        >
          <tspan x="37.5" y="17.5">
            72
          </tspan>
        </text>
      </g>
      <g id="721">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.55 729.086h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.55 729.086)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="722">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.55 754.786h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.55 754.786)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="740">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M428.55 549.186h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(428.55 549.186)"
        >
          <tspan x="37.5" y="17.5">
            74
          </tspan>
        </text>
      </g>
      <g id="741">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.55 574.886h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.55 574.886)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="742">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.55 600.586h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.55 600.586)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="800">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M329.59 934.685h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(329.59 934.685)"
        >
          <tspan x="37.5" y="17.5">
            80
          </tspan>
        </text>
      </g>
      <g id="801">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.59 960.378h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.59 960.378)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="802">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.59 986.078h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.59 986.078)"
        >
          <tspan x="32.5" y="17.5">
            메모
          </tspan>
        </text>
      </g>
      <g id="690">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M428.74 935.188h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(428.74 935.188)"
        >
          <tspan x="37.5" y="17.5">
            69
          </tspan>
        </text>
      </g>
      <g id="691">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.74 960.888h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.74 960.888)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="692">
        <path
          fill="#fff"
          stroke="#101843"
          d="M428.74 986.588h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(428.74 986.588)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape265">
        <path
          fill="#7e7e7e"
          d="M205.372 548.286v78.4l3.8 3.7-3.8 3.8v794.9l7.6-7.5v-401.8l-3.8-3.7 3.8-3.8v-464z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M205.372 548.286v880.8m7.6-7.5v-873.3m-7.6 0h7.6"
        ></path>
      </g>
      <g id="820">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M213.12 838.48h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(213.12 838.48)"
        >
          <tspan x="37.5" y="17.5">
            82
          </tspan>
        </text>
      </g>
      <g id="821">
        <path
          fill="#fff"
          stroke="#101843"
          d="M213.12 864.18h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(213.12 864.18)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="822">
        <path
          fill="#fff"
          stroke="#101843"
          d="M213.12 889.88h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(213.12 889.88)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="810">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M213.12 935.232h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(213.12 935.232)"
        >
          <tspan x="37.5" y="17.5">
            81
          </tspan>
        </text>
      </g>
      <g id="811">
        <path
          fill="#fff"
          stroke="#101843"
          d="M213.12 960.932h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(213.12 960.932)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="812">
        <path
          fill="#fff"
          stroke="#101843"
          d="M213.12 986.632h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(213.12 986.632)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="840">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M213.12 645.007h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(213.12 645.007)"
        >
          <tspan x="37.5" y="17.5">
            84
          </tspan>
        </text>
      </g>
      <g id="841">
        <path
          fill="#fff"
          stroke="#101843"
          d="M213.12 670.707h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(213.12 670.707)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="842">
        <path
          fill="#fff"
          stroke="#101843"
          d="M213.12 696.407h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(213.12 696.407)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="830">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M213.12 741.76h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(213.12 741.76)"
        >
          <tspan x="37.5" y="17.5">
            83
          </tspan>
        </text>
      </g>
      <g id="831">
        <path
          fill="#fff"
          stroke="#101843"
          d="M213.12 767.46h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(213.12 767.46)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="832">
        <path
          fill="#fff"
          stroke="#101843"
          d="M213.12 793.16h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(213.12 793.16)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="850">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M212.93 548.689h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(212.93 548.689)"
        >
          <tspan x="37.5" y="17.5">
            85
          </tspan>
        </text>
      </g>
      <g id="851">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.93 574.389h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.93 574.389)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="852">
        <path
          fill="#fff"
          stroke="#101843"
          d="M212.93 600.089h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(212.93 600.089)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="140">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M329.718 418.465h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(329.718 418.465)"
        >
          <tspan x="37.5" y="17.5">
            14
          </tspan>
        </text>
      </g>
      <g id="141">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.718 444.165h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.718 444.165)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="142">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.718 469.865h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.718 469.865)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="120">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M329.528 212.865h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(329.528 212.865)"
        >
          <tspan x="37.5" y="17.5">
            12
          </tspan>
        </text>
      </g>
      <g id="121">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.528 238.565h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.528 238.565)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="122">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.528 264.265h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.528 264.265)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="130">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M329.528 315.665h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(329.528 315.665)"
        >
          <tspan x="37.5" y="17.5">
            13
          </tspan>
        </text>
      </g>
      <g id="131">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.528 341.365h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.528 341.365)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="132">
        <path
          fill="#fff"
          stroke="#101843"
          d="M329.528 367.065h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(329.528 367.065)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="110">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M328.924 110.065h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(328.924 110.065)"
        >
          <tspan x="37.5" y="17.5">
            11
          </tspan>
        </text>
      </g>
      <g id="111">
        <path
          fill="#fff"
          stroke="#101843"
          d="M328.924 135.765h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(328.924 135.765)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="112">
        <path
          fill="#fff"
          stroke="#101843"
          d="M328.924 161.465h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(328.924 161.465)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="470">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M978.523 935.178h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(978.523 935.178)"
        >
          <tspan x="37.5" y="17.5">
            47
          </tspan>
        </text>
      </g>
      <g id="471">
        <path
          fill="#fff"
          stroke="#101843"
          d="M978.523 960.878h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(978.523 960.878)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="472">
        <path
          fill="#fff"
          stroke="#101843"
          d="M978.523 986.578h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(978.523 986.578)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="450">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M977.579 676.177h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(977.579 676.177)"
        >
          <tspan x="37.5" y="17.5">
            45
          </tspan>
        </text>
      </g>
      <g id="451">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.579 701.877h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.579 701.877)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="452">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.579 727.577h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.579 727.577)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="460">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M977.58 806.41h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(977.58 806.41)"
        >
          <tspan x="37.5" y="17.5">
            46
          </tspan>
        </text>
      </g>
      <g id="461">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.58 832.11h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.58 832.11)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="462">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.58 857.81h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.58 857.81)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="440">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M977.58 548.683h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(977.58 548.683)"
        >
          <tspan x="37.5" y="17.5">
            44
          </tspan>
        </text>
      </g>
      <g id="441">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.58 574.383h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.58 574.383)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="442">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.58 600.083h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.58 600.083)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="420">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M977.294 367.667h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(977.294 367.667)"
        >
          <tspan x="37.5" y="17.5">
            42
          </tspan>
        </text>
      </g>
      <g id="421">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.294 393.367h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.294 393.367)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="422">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.294 419.067h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.294 419.067)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="430">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M977.294 444.767h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(977.294 444.767)"
        >
          <tspan x="37.5" y="17.5">
            43
          </tspan>
        </text>
      </g>
      <g id="431">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.294 470.467h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.294 470.467)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="432">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.294 496.167h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.294 496.167)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="410">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M977.294 291.067h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(977.294 291.067)"
        >
          <tspan x="37.5" y="17.5">
            41
          </tspan>
        </text>
      </g>
      <g id="411">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.294 316.767h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.294 316.767)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="412">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.294 342.467h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.294 342.467)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="400">
        <path
          fill="#ddcdff"
          stroke="#101843"
          d="M977.294 215.027h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(977.294 215.027)"
        >
          <tspan x="37.5" y="17.5">
            40
          </tspan>
        </text>
      </g>
      <g id="401">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.294 240.727h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.294 240.727)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="402">
        <path
          fill="#fff"
          stroke="#101843"
          d="M977.294 266.427h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(977.294 266.427)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape317">
        <path
          fill="#7e7e7e"
          d="M1069.606 215.042v1206.5l3.8 3.8 3.8 3.8v-1217.5l-3.8 1.7z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M1069.606 215.042v1206.5m7.6 7.6v-1217.5"
        ></path>
      </g>
      <g id="shape318">
        <path
          fill="#7e7e7e"
          d="m952.946 110.239 116.65 104.78 3.805-1.691 3.804-1.691-121.263-108.924-1.498 3.763z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="m952.946 110.239 116.65 104.78m7.609-3.382L955.942 102.713"
        ></path>
      </g>
      <g id="540">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M761.742 780.468h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.742 780.468)"
        >
          <tspan x="37.5" y="17.5">
            54
          </tspan>
        </text>
      </g>
      <g id="541">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.742 806.168h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.742 806.168)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="542">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.742 831.868h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.742 831.868)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="550">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M761.742 857.568h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.742 857.568)"
        >
          <tspan x="37.5" y="17.5">
            55
          </tspan>
        </text>
      </g>
      <g id="551">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.742 883.268h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.742 883.268)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="552">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.742 908.968h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.742 908.968)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="520">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M761.552 626.268h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.552 626.268)"
        >
          <tspan x="37.5" y="17.5">
            52
          </tspan>
        </text>
      </g>
      <g id="521">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.552 651.968h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.552 651.968)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="522">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.552 677.668h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.552 677.668)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="530">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M761.552 703.368h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.552 703.368)"
        >
          <tspan x="37.5" y="17.5">
            53
          </tspan>
        </text>
      </g>
      <g id="531">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.552 729.068h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.552 729.068)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="532">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.552 754.768h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.552 754.768)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="510">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M761.552 549.168h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.552 549.168)"
        >
          <tspan x="37.5" y="17.5">
            51
          </tspan>
        </text>
      </g>
      <g id="511">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.552 574.868h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.552 574.868)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="512">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.552 600.568h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.552 600.568)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="560">
        <path
          fill="#ffecd1"
          stroke="#101843"
          d="M761.742 935.17h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          fontWeight="bold"
          transform="translate(761.742 935.17)"
        >
          <tspan x="37.5" y="17.5">
            56
          </tspan>
        </text>
      </g>
      <g id="561">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.742 960.863h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.742 960.863)"
        >
          <tspan x="32.5" y="17.3">
            이름
          </tspan>
        </text>
      </g>
      <g id="562">
        <path
          fill="#fff"
          stroke="#101843"
          d="M761.742 986.563h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="13.333"
          transform="translate(761.742 986.563)"
        >
          <tspan x="32.5" y="17.3">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape337">
        <path
          fill="#7e7e7e"
          d="M105.544 102.691v531.4l3.8-3.8 3.8-3.7v-516.3l-3.8-3.8z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M105.544 102.691v531.4m7.6-7.5v-516.3"
        ></path>
      </g>
      <g id="shape338">
        <path
          fill="#7e7e7e"
          d="m205.356 626.642-92.3-.09-3.704 3.795-3.804 3.797 99.8.098 3.804-3.796z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="m205.356 626.642-92.3-.09m-7.508 7.592 99.8.098"
        ></path>
      </g>
    </g>
  </svg>
)};

export default MolipSeatSvg;
